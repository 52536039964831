<template>
  <div
    id="qualityCertificateId"
    style="width: 100%; height: 100%; overflow-y: scroll; font-size: 14px"
    v-if="record"
   >
    <component :is="'style'">
      table.print, table.print tr, table.print td { border: 0.5px solid red;
      border-collapse: collapse; font-size:16px; font-family:SimSun; }
      table.print td table td{ width: 99px; } table.print td{ width: 100px;
      text-align: center; height: 40px; position: relative; } table.print
      td[class=first] { width: 100px; } table.print td[class=first] span.slash {
      position: absolute; width: 1px; height: 115px; top: 0; left: 0;
      border-left: 1px solid #000000; display: block; transform: translate(0,
      82%) rotate(-145deg); transform-origin: top; -ms-transform:
      rotate(-60deg); -ms-transform-origin: top; } table.print .rowTitle {
      position: absolute; top: 15px; left: 10px; } table.print .rowTitle2 {
      position: absolute; bottom: 15px; right: 10px; }
    </component>
    <div v-for="(parent, index) in reports" :key="index">
      <div style="margin-bottom: 15px; font-family: SimSun">
        <div style="width: 100%; display: flex; align-items: flex-end">
          <div style="width: 33%">
            <img
              style="width: 90px; height: 80px; margin-bottom: 5px"
              src="../assets/image/AS.jpg"
            />
          </div>
          <div
            style="
              width: 34%;
              display: inline-block;
              margin: 0 10px 10px 10px;
              box-sizing: border-box;
            "
          >
            <div
              style="font-size: 31px; text-align: center; letter-spacing: 3px"
            >
              四川雅安安山钢铁有限公司
              <br />
              <div style="font-size: 16px; text-align: center">
                SICHUAN YAAN ANSHAN STEEL CO.LTD.
              </div>
            </div>
          </div>
          <div style="width: 33%">
            <img
              style="
                width: 90px;
                height: 90px;
                float: right;
                margin-bottom: 5px;
              "
              src="../assets/image/XKZH.jpg"
            />
          </div>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            border-bottom: 1px solid red;
            line-height: 24px;
            align-items: flex-end;
          "
        >
          <div style="width: 36%">
            <div>编码：{{ record.serialNO }}<br />SERLAL NO：</div>
          </div>
          <div style="width: 31%">
            许可证号：（川）XK05-001-00003<br />LICENCE：
          </div>
          <div style="width: 33%">
            证书编号：{{ record.certificateNO }}<br />CERTIEICATE NO：
          </div>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            border-bottom: 1px solid red;
            align-items: center;
            line-height: 24px;
          "
        >
          <div style="width: 67%">
            订货单位：{{ record.customer }}<br />CUSTOMER：
          </div>
          <div style="width: 33%">
            技术条件：{{ record.reports[0].standardName }}<br />TECHNICAL：
          </div>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            border-bottom: 1px solid red;
            align-items: center;
            line-height: 24px;
            border-bottom: 0;
          "
        >
          <div style="border-bottom: 1px solid red; width: 33%">
            <div>收货单位：{{ record.consignee }}<br />CONSIGNEE：</div>
          </div>
          <div
            style="
              width: 33.5%;
              display: inline-block;
              margin: 0 10px;
              box-sizing: border-box;
              position: relative;
            "
          >
            <div
              style="
                font-size: 26px;
                text-align: center;
                position: absolute;
                bottom: -33px;
                right: 100px;
                letter-spacing: 5px;
              "
            >
              钢筋混凝土用热轧钢筋质量书
            </div>
          </div>
          <div style="border-bottom: 1px solid red; width: 33.5%">
            <div>交货状态：{{ record.deliveryCondition }}<br />DELIVERY：</div>
          </div>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            border-bottom: 1px solid red;
            align-items: center;
            line-height: 24px;
          "
        >
          <div style="width: 16%">
            到站：{{ record.destination }}<br />DESTINATION：
          </div>
          <div style="width: 17%">
            车号：{{ record.vehicleNO }}<br />VEHICLE NO：
          </div>
          <div
            style="
              width: 33.5%;
              display: inline-block;
              margin: 0 10px;
              box-sizing: border-box;
              position: relative;
              text-align: center;
            "
          >
            <div style="font-size: 16px; position: absolute; right: 165px">
              QUALITY CERTIFICATE OF HOT ROLLED STEEL<br />BARS FOR REINFORCED
              CONCRETE
            </div>
          </div>
          <div style="width: 33.5%">
            合同号：{{ record.contractNO }}<br />CONTRACT NO：
          </div>
        </div>
        <div style="float: right; position: relative; width: 100%">
          <div
            style="
              position: absolute;
              top: 0;
              width: 30%;
              right: 0;
              line-height: 24px;
            "
          >
            交货日期：{{ dateYMDFormat(nowTime)}}<br />DATE：
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          line-height: 24px;
          width: 25%;
          font-family: SimSun;
        "
      >
        <div>
          产品名称：{{ record.reports[0].prodName
          }}{{ record.reports[0].steelGradeName }}<br />PRODUCT：
        </div>
      </div>

      <table class="print" style="width: 100%">
        <thead>
          <tr align="center">
            <td rowspan="3" style="width: 200px">批号<br />BATCH NG.</td>
            <td rowspan="3">规格<br />SPEC<br />（mm）</td>
            <td rowspan="3">长度<br />LENGTH<br />（m）</td>
            <td rowspan="3">数量<br />QUANTITY<br />（捆/卷）</td>
            <td rowspan="3">重量<br />MASS<br />（t）</td>
            <td :colspan="record.reports[0].cfemisal.length">
              化学成分<br />CFEMISAL COMPOSITION%
            </td>
            <td :colspan="record.reports[0].mechancal.length">
              力学性能<br />MECHAN＇CAL PROPERTY
            </td>
            <td
              :colspan="
                record.reports[0].technological.length == 0
                  ? 1
                  : record.reports[0].technological.length
              "
            >
              工艺性能<br />TECHNOLOGICAL PERFORMANCE
            </td>
            <td rowspan="3">重量偏差<br />WEIGHT DEVIATION</td>
            <td rowspan="3">金相组织<br />METALLOGRAPHIC STRUCTURE</td>
            <td rowspan="3">表面质量<br />SURFACE MASS</td>
          </tr>
          <tr align="center">
            <td
              v-for="(item, index) in record.reports[0].cfemisal"
              :key="index"
            >
              {{ item.projectCategory }}
            </td>
            <td
              v-for="(item, index) in getWithUnitMechancal(
                record.reports[0].mechancal
              )"
              :key="index"
            >
              {{ item.projectCategory }}
            </td>
            <td
              rowspan="2"
              v-for="(item, index) in getNonUnitMechancal(
                record.reports[0].mechancal
              )"
              :key="index"
            >
              {{ item.projectCategory }}
            </td>
            <td
              rowspan="2"
              v-for="(techItem, index) in record.reports[0].technological"
              :key="index"
              v-html="
                getFormatString(
                  techItem.projectCategory,
                  record.reports[0].technological
                )
              "
            ></td>
          </tr>
          <tr align="center">
            <td
              v-for="(item, index) in record.reports[0].cfemisal"
              :key="index"
            >
              {{ item.projectUnit }}
            </td>
            <td
              v-for="(item, index) in getWithUnitMechancal(
                record.reports[0].mechancal
              )"
              :key="index"
            >
              {{ item.projectUnit }}
            </td>
          </tr>
        </thead>
        <tbody v-for="(item, index) in parent" :key="index">
          <tr>
            <td :rowspan="3">{{ item.batchCode ? item.batchCode : "/" }}</td>
            <td :rowspan="3">{{ item.spec ? item.spec : "/" }}</td>
            <td :rowspan="3">{{ item.length ? item.length : "/" }}</td>
            <td :rowspan="3">{{ item.quantity ? item.quantity : "/" }}</td>
            <td :rowspan="3">{{ trueName ? item.theoWeight : item.weight }}</td>
            <td v-if="item.cfemisal.length == 0">/</td>
            <td v-for="(item, index) in item.cfemisal" :key="index">
              {{ item.itemVal ? item.itemVal : "/" }}
            </td>
            <td v-if="item.mechancal.length == 0">/</td>
            <td
              v-for="(item, index) in getWithUnitMechancal(item.mechancal)"
              :key="index"
            >
              {{ item.itemVal ? item.itemVal : "/" }}
            </td>
            <td
              v-for="(item, index) in getNonUnitMechancal(item.mechancal)"
              :key="index"
            >
              {{ item.itemVal ? item.itemVal : "/" }}
            </td>
            <td v-if="item.technological.length == 0">/</td>
            <td v-for="(techItem, index) in item.technological" :key="index">
              {{
                techItem.itemVal
                  ? isPerfect(item.mechancal, "")
                    ? "完好"
                    : "/"
                  : "/"
              }}
            </td>
            <td v-for="(perfectItem, index) in [0, 1, 2]" :key="index">
              {{ isPerfect(item.mechancal, "") ? "合格" : "/" }}
            </td>
          </tr>
          <tr>
            <td v-if="item.cfemisal.length == 0">/</td>
            <td v-for="(item, index) in item.cfemisal" :key="index">
              {{ item.itemVal2 ? item.itemVal2 : "/" }}
            </td>
            <td v-if="item.mechancal.length == 0">/</td>
            <td
              v-for="(item, index) in getWithUnitMechancal(item.mechancal)"
              :key="index"
            >
              {{ item.itemVal2 ? item.itemVal2 : "/" }}
            </td>
            <td
              v-for="(item, index) in getNonUnitMechancal(item.mechancal)"
              :key="index"
            >
              {{ item.itemVal2 ? item.itemVal2 : "/" }}
            </td>
            <td v-if="item.technological.length == 0">/</td>
            <td v-for="(techItem, index) in item.technological" :key="index">
              {{
                techItem.itemVal
                  ? isPerfect(item.mechancal, "2")
                    ? "完好"
                    : "/"
                  : "/"
              }}
            </td>
            <td v-for="(perfectItem, index) in [0, 1, 2]" :key="index">
              {{ isPerfect(item.mechancal, "2") ? "合格" : "/" }}
            </td>
          </tr>
          <tr>
            <td v-if="item.cfemisal.length == 0">/</td>
            <td v-for="(item, index) in item.cfemisal" :key="index">
              {{ item.itemVal3 ? item.itemVal3 : "/" }}
            </td>
            <td v-if="item.mechancal.length == 0">/</td>
            <td
              v-for="(item, index) in getWithUnitMechancal(item.mechancal)"
              :key="index"
            >
              {{ item.itemVal3 ? item.itemVal3 : "/" }}
            </td>
            <td
              v-for="(item, index) in getNonUnitMechancal(item.mechancal)"
              :key="index"
            >
              {{ item.itemVal3 ? item.itemVal3 : "/" }}
            </td>
            <td v-if="item.technological.length == 0">/</td>
            <td v-for="(techItem, index) in item.technological" :key="index">
              {{
                techItem.itemVal
                  ? isPerfect(item.mechancal, "3")
                    ? "完好"
                    : "/"
                  : "/"
              }}
            </td>
            <td v-for="(perfectItem, index) in [0, 1, 2]" :key="index">
              {{ isPerfect(item.mechancal, "3") ? "合格" : "/" }}
            </td>
          </tr>
        </tbody>

        <!--空的-->
        <!-- <tr>
          <td :rowspan="3"></td>
          <td :rowspan="3"></td>
          <td :rowspan="3"></td>
          <td :rowspan="3"></td>
          <td :rowspan="3"></td>
          <td v-if="item.cfemisal.length == 0"></td>
          <td v-for="(item, index) in item.cfemisal" :key="index"></td>
          <td v-if="item.mechancal.length == 0"></td>
          <td v-for="(item, index) in item.mechancal" :key="index"></td>
          <td v-if="item.technological.length == 0"></td>
          <td v-for="(item, index) in item.technological" :key="index"></td>
          <td v-for="(item, index) in [0,1,2]" :key="index">

          </td>
        </tr>
        <tr>
          <td v-if="item.cfemisal.length == 0"></td>
          <td v-for="(item, index) in item.cfemisal" :key="index"></td>
          <td v-if="item.mechancal.length == 0"></td>
          <td v-for="(item, index) in item.mechancal" :key="index"></td>
          <td v-if="item.technological.length == 0"></td>
          <td v-for="(item, index) in item.technological" :key="index"></td>
          <td v-for="(item, index) in [0,1,2]" :key="index">

          </td>
        </tr>
        <tr>
          <td v-if="item.cfemisal.length == 0"></td>
          <td v-for="(item, index) in item.cfemisal" :key="index"></td>
          <td v-if="item.mechancal.length == 0"></td>
          <td v-for="(item, index) in item.mechancal" :key="index"></td>
          <td v-if="item.technological.length == 0"></td>
          <td v-for="(item, index) in item.technological" :key="index"></td>
          <td v-for="(item, index) in [0,1,2]" :key="index"></td>
        </tr> -->
        <tr align="center">
          <td colspan="23" style="text-align: left; padding-left: 20px">
            注释（NOTES）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Y.S.=YIELD
            STRENGTH
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;T.S.=TENSILE
            STRENG&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EL=ELONGATION
          </td>
        </tr>
        <tr>
          <td
            colspan="23"
            style="font-size: 20px; text-align: left; padding-left: 20px"
          >
            <img
              style="
                width: 40px;
                height: 40px;
                margin-right: 5px;
                vertical-align: middle;
              "
              :src="require('../assets/image/TB.jpg')"
            />
            <span
              >已被评为省级绿色工厂（IT HAS BEEN RATED AS A PROVINCIAL GREEN
              FACTORY）</span
            >
          </td>
        </tr>
        <tr>
          <td colspan="23" style="text-align: left; padding-left: 20px">
            本产品已按上述要求进行制造和检验，其结果符合要求，特此证明：质量证明书复印件不作有效证明文件。<br />
            WE HEREBY CERTIFY THAT MATERIAL DESCRIBED HEREIN HAS MANUFACTURED
            AND TESTED WITH SATISFACTORY RESULTS IN ACCORDAVCE WITH THE
            REQUIREMENTS OF THE ABOVE MATERIAL SPECIFICATION.
          </td>
        </tr>
      </table>

      <div
        style="
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-bottom: 70px;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            line-height: 24px;
            position: relative;
            font-family: SimSun;
          "
        >
          <span>质量负责人（QUALITY DIRECTOR）石孙俤</span>
          <span>厂址：雅安市天全县</span>
          <span>ADD：TIANQUAN COUNTY YAAN CITY</span>
          <div style="position: absolute; bottom: -55px; right: 0">
            <img
              style="width: 220px; height: 220px"
              :src="require('../assets/logo3.png')"
            />
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            line-height: 24px;
            font-family: SimSun;
          "
        >
          <span>发货员（CONSIGNOR）：陈克清</span>
          <span>邮编：625599</span>
          <span>POSTCODE：</span>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            line-height: 24px;
            position: relative;
            font-family: SimSun;
          "
        >
          <span>质保员（INSPECTOR）：高琴</span>
          <span>销售服务：0835-5995959 姚玉珍</span>
          <span>DISTRIBUTION：</span>
          <div style="position: absolute; top: -25px; right: 60px">
            <img
              style="width: 160px; height: 120px"
              :src="require('../assets/logo2.png')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QualityCertificate",
  props: {
    no: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      total: 2,
      trueName: true,
      currPage: 1,
      pageSize: 10,
      selectForm: {
        vehicleNO: "",
      },
      tableList: [],
      mechancal: [], //力学性能
      cfemisal: [], //化学成分
      technological: [], //工艺性能
      others: [], //其他
      report: null,
      record: null,
      reports: [],
      nowTime:""
    };
  },
  computed: {
    getRowNum() {
      let num = 0;
      console.log(this.dataList[0].reports[0]);
      this.dataList[0].reports.forEach((ele) => {
        num += ele.properties.length;
      });
      return 6 - num;
    },
  },
  created(){
    var date=new Date();
    this.nowTime=date.toLocaleDateString()
  },
  methods: {
    getWithUnitMechancal(mechancal) {
      if (mechancal && mechancal.length > 0) {
        return mechancal.filter((item) => {
          return item.projectUnit;
        });
      }
    },
    getNonUnitMechancal(mechancal) {
      if (mechancal && mechancal.length > 0) {
        return mechancal.filter((item) => {
          return !item.projectUnit;
        });
      }
    },
    isPerfect(mer, suffix) {
      for (let i in mer) {
        if (typeof mer[i]["itemVal" + suffix] != "undefined") {
          return true;
        }
      }
      return false;
    },
    getFormatString(str, technological) {
      let arr = str.split(" ");
      let strtemp = [];
      let strtmp = arr.join("<br/>");
      if (technological.length > 0) {
        technological.map((e) => {
          //遍历数据
          if (e.projectCategory === str) {
            strtemp = e.itemVal ? e.itemVal : "";
          }
        });
      }
      return strtmp.replace("（?）", "(" + strtemp + ")");
    },
    print(id, batchCode, trueName) {
      this.trueName = trueName;
      const callback = () => {
        this.$nextTick(() => {
          var newWindow = window.open("打印", "_blank");
          var docStr = document.getElementById(
            "qualityCertificateId"
          ).innerHTML;
          newWindow.document.write(docStr);
          var imgNum = newWindow.document.getElementsByTagName("img").length;
          if (imgNum > 0) {
            newWindow.document.getElementsByTagName("img")[imgNum - 1].onload =
              function () {
                setTimeout(function () {
                  newWindow.print();
                }, 300);
              };
          } else {
            newWindow.print();
            setTimeout(function () {
              newWindow.close();
            }, 300);
          }
        });
      };
      this.getList(id, batchCode, callback);
    },
    getList(id, batchCode, callback) {
      var _this = this;
      //二维数组拆分成一维数组
      var outputbatchCode = [];
      if (batchCode) {
        batchCode.forEach((item) => {
          outputbatchCode.push(...item);
        });
      }
      this.postRequest("/qc/getList", {
        pageNum: 1,
        pageSize: 10,
        id: id,
        batchCodes: outputbatchCode, //一维数组
      }).then((data) => {
        if (data.success) {
          let records = data.data.records;
          _this.record = records[0];
          _this.reports = []; //一维数组对象

          if (records) {
            console.log(records);
            for (let j = 0; j < records.length; j++) {
              let element = records[j].reports;
              for (let index = 0; index < batchCode.length; index++) {
                var item = [];
                const batchitem = batchCode[index];
                var elements = element.filter(
                  (i) => batchitem.indexOf(i.batchCode) >= 0
                );
                item.push(...elements);
                if (item) {
                  var obj = {};
                  if (item[0]) {
                    for (let key in item[0]) {
                      if (Array.isArray(item[0][key])) {
                        obj[key] =[];
                        for (let p = 0; p < item[0][key].length; p++) {
                          obj[key].push({});
                        }
                      } else {
                        obj[key] = "";
                      }
                    }
                  }
                  for (let k = 0; k < 4; k++) {
                    if (!item[k]) {
                      item.push(obj);
                    }
                  }
                  _this.reports.push(item);
                }
              }
            }
          }
          //   this.report = {
          //     quantity: reports[0].quantity,
          //     batchCode: reports[0].batchCode,
          //     length: reports[0].length,
          //     weight: reports[0].weight,
          //     spec: reports[0].spec,
          //     prodName: reports[0].prodName,
          //     steelGradeName: reports[0].steelGradeName,
          //     standardName: reports[0].standardName
          //   }
          //   this.others = reports[0].others;
          //   this.technological = reports[0].technological;
          //   this.mechancal = reports[0].mechancal;
          //   this.cfemisal = reports[0].cfemisal;
          // } else {
          //   this.report = {
          //     quantity: '',
          //     batchCode: '',
          //     length: '',
          //     weight: '',
          //     spec: '',
          //     prodName:'',
          //     steelGradeName:'',
          //     standardName:''
          //   }
          //   this.others = '';
          //   this.technological = '';
          //   this.mechancal = '';
          //   this.cfemisal = '';
          // }
          console.log(this.reports);
          this.$forceUpdate();
          callback && callback();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.print-heade {
  .print-heade-row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid red;
    align-items: center;
    line-height: 24px;

    .row-con-title {
      width: 400px;
      display: inline-block;
      margin: 0 10px;
      box-sizing: border-box;
    }

    .print-heade-row-con {
      flex: 1;

      .row-con-item {
        display: flex;
        align-items: center;
      }
    }
  }
}

.title {
  font-size: 28px;
  letter-spacing: 5px;
  text-align: center;
  margin: 0;
}

.title2 {
  font-size: 20px;
  text-align: center;
}

.title3 {
  font-size: 24px;
  text-align: center;
  position: absolute;
  bottom: -33px;
  right: 15px;
  letter-spacing: 5px;
}

.title4 {
  font-size: 18px;
  text-align: center;
  position: absolute;
}

.date {
  width: 130px;
  position: absolute;
  top: 0;
  right: 325px;
  line-height: 24px;
}

.image {
  float: right;
  margin-right: 10px;
  height: 75px;
  width: 100px;
}

.footer {
  display: flex;
  align-items: flex-end;
}

.left {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  width: 25%;
}

//表格
table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  line-height: 30px;
  height: 40px;
  overflow-x: scroll;
}

.contentMain {
  text-align: left;
  padding-left: 20px;
}
</style>

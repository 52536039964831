<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form
        :inline="true"
        :model="selectForm"
        class="el-form-inline"
        size="mini"
      >
        <el-form-item class="row" label="车牌号：">
          <el-input
            v-model="selectForm.vehicleNO"
            placeholder="请输入车牌号"
            size="mini"
            clearable
            type="text"
          ></el-input>
        </el-form-item>

        <el-form-item class="row" label="批号：">
          <el-input
            v-model="selectForm.batchCode"
            placeholder="请输入车牌号"
            size="mini"
            clearable
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item class="row" label="生成时间：">
          <el-date-picker
            type="date"
            format="YYYY-MM-DD"
            size="mini"
            v-model="selectForm.createTime"
            clearable
            :editable="false"
            placeholder="请选择生成时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            class="btn"
            size="mini"
            @click="readData"
            :disabled="readDataDisabled"
            >查询</el-button
          >
        </el-form-item>

        <el-form-item style="float: right">
          <!-- `checked` 为 true 或 false -->
          <el-checkbox v-model="trueName">是否打印理重</el-checkbox>
        </el-form-item>

        <!--<el-form-item style="float: right">-->
        <!--<el-button class="btn" size="mini" v-if="trueName" @click="trueData">实重打印</el-button>-->
        <!--</el-form-item>-->
        <!--<el-form-item style="float: right">-->
        <!--<el-button class="btn" size="mini" v-if="falsetName" @click="falseData">理重打印</el-button>-->
        <!--</el-form-item>-->
      </el-form>
    </div>
    <el-table
      :data="dataList"
      class="main-table"
      stripe
      row-key="id"
      :header-row-style="{
        background: '#DEEBF6',
        color: '#000000',
        height: '50px',
        fontWeight: 'Medium',
      }"
      :header-cell-style="{
        background: '#DEEBF6',
        color: '#000000',
        height: '50px',
        fontWeight: 'Medium',
      }"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div v-if="props.row.reports && props.row.reports.length > 0">
            <el-table
              :data="props.row.reports"
              class="inner-table"
              border
              :span-method="objectSpanMethod"
              ref="multipleTable"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                align="center"
                fixed="left"
              ></el-table-column>
              <el-table-column
                type="index"
                width="80px"
                label="序号"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="batchCode"
                label="批号"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="length"
                label="长度"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="spec"
                label="规格"
                align="center"
              ></el-table-column>
              <el-table-column prop="weight" label="重量" align="center">
                <template v-slot="scope">
                  {{ trueName ? scope.row.theoWeight : scope.row.weight }}
                </template>
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="数量"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="steelGradeName"
                label="钢种"
                align="center"
              ></el-table-column>
              <el-table-column align="center" fixed="right" label="操作">
                <template v-slot="scope">
                  <el-button
                      type="text"
                      size="small"
                      @click="
                        print(scope.row.id, scope.row.batchCode, props,props.row.materialName,props.row.reports)
                    "
                  >打印</el-button
                  >
                  <!--                  <el-button-->
                  <!--                      type="text"-->
                  <!--                      size="small"-->
                  <!--                      @click="-->
                  <!--                      printClick2(scope.row.id)-->
                  <!--                    "-->
                  <!--                  >打印</el-button-->
                  <!--                  >-->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :index="table_index"
        type="index"
        align="center"
        label="序号"
        width="80px"
      >
      </el-table-column>
      <!--<el-table-column-->
      <!--label="订货单位"-->
      <!--align="center"-->
      <!--prop="customer">-->
      <!--</el-table-column>-->
      <el-table-column label="物料名称" align="center" prop="materialName">
      </el-table-column>
      <el-table-column label="钢种" align="center" prop="specification">
      </el-table-column>
      <el-table-column label="规格" align="center" prop="steelBall">
      </el-table-column>
      <el-table-column label="车牌号" align="center" prop="vehicleNO">
      </el-table-column>
      <el-table-column label="生成时间" align="center" prop="createTime">
        <template #default="scope">
          {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : "" }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-button type="text" size="small" @click="showDrawerFunc(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="printClicks(scope.row.id)"
            >批量打印</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="elp"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <quality-certificate
      ref="qualityCertificate"
      id="qualityCertificateId"
      style="display: none"
    ></quality-certificate>
    <!--    编辑-->
    <el-drawer
      custom-class="drawerMain"
      v-model="showDrawer"
      v-if="showDrawer"
      title="编辑"
      size="520px"
      direction="rtl"
    >
      <div class="drawer_content">
        <el-form
          :model="processForm"
          :rules="rules"
          ref="processForm"
          label-position="top"
        >
          <el-form-item label="编码" prop="serialNO">
            <el-input
              v-model="processForm.serialNO"
              placeholder="请填写编码"
              clearable
              type="text"
              size="mini"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="收货单位" prop="consignee">
            <el-input
              v-model="processForm.consignee"
              placeholder="请填写收货单位"
              clearable
              type="text"
              size="mini"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="到站" prop="destination">
            <el-input
              v-model="processForm.destination"
              placeholder="请填写到站"
              clearable
              type="text"
              size="mini"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书编号" prop="certificateNO">
            <el-select
              v-model="processForm.certificateNO"
              placeholder="请选择证书编号"
              size="mini"
              clearable
            >
              <el-option
                v-for="(item, index) in projectList"
                :key="index"
                :label="item.projectValue"
                :value="item.projectValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="交货状态" prop="deliveryCondition">
            <el-select
              v-model="processForm.deliveryCondition"
              placeholder="请选择交货状态"
              size="mini"
              clearable
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.projectValue"
                :value="item.projectValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button size="mini" @click="showDrawer = false">取 消</el-button>
          <el-button
            type="primary"
            size="mini"
            @click="submitBtnClick('processForm')"
            >确 定
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import QualityCertificate from "@/pages/QualityCertificate";
import moment from "moment";

export default {
  name: "QueryReport",
  components: { QualityCertificate },
  data() {
    return {
      readDataDisabled: true,
      weightToCancel: 2,
      trueName: true,
      falsetName: false,

      total: 2,
      currPage: 1,
      pageSize: 10,
      selectForm: {
        vehicleNO: "",
        batchCode: "",
        createTime: new Date(),
      },
      dataList: [],
      showDrawer: false,
      processForm: {
        id: "",
        serialNO: "",
        consignee: "",
        destination: "",
        deliveryCondition: "",
        certificateNO: "",
      },
      projectList: [],
      technicalList: [],
      statusList: [],
      //禁用当前时间往后的日期
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      multipleSelection: null,
    };
  },
  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.getProject();
  },
  methods: {
    //获取下拉列表
    getProject() {
      this.postFormRequest("/tb-book-portion/selectList").then((res) => {
        if (res.success) {
          this.projectList = res.data.b1;
          this.technicalList = res.data.b2;
          this.statusList = res.data.b3;
        }
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 4 === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      } else if (columnIndex === 8) {
        if (rowIndex % 4 === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    // changeSelect(vId) {
    //   let obj = {};
    //   obj = this.technicalList.find((item)=>{
    //     return item.id === vId
    //   })
    //   this.processForm.technical = obj.projectValue
    //   console.info(this.processForm.technical)
    // },
    //查询
    //实重
    trueData() {
      this.weightToCancel = 1;
      this.falsetName = true;
      this.trueName = false;
    },
    //理重
    falseData() {
      this.weightToCancel = 2;
      this.trueName = true;
      this.falsetName = false;
    },
    readData() {
      this.currentPageChanged(this.currPage, this.pageSize);
      this.readDataDisabled = true;
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1;
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      let createTime = this.selectForm.createTime;
      this.postRequest("/qc/getList", {
        pageNum: currPage,
        pageSize: pageSize,
        vehicleNO: this.selectForm.vehicleNO,
        batchCode: this.selectForm.batchCode,
        createTime:
          createTime == null
            ? ""
            : moment(createTime).format("yyyy-MM-DD HH:mm:ss"),
      }).then((res) => {
        if (res.success) {
          this.dataList = res.data.records;
          for (let index = 0; index < this.dataList.length; index++) {
            var seq = 0;
            for (let j = 0; j < this.dataList[index].reports.length; j++) {
              this.dataList[index].reports[j].seq = seq;
              seq += 1;
            }
          }
          this.total = res.data.total;
          this.readDataDisabled = false;
        }
      });
    },
    //打印总方法
    print(id, batchCode, d,name,f){
      let batchCodeList = [];
      for(let i=0;i<f.length;i++){
        let item = f[i].batchCode;
        batchCodeList.push(item);
      }
      let batchCodeStr = batchCodeList.join();
      if(name != null && name.toString().includes("成实")){
        this.printClick2(id,batchCodeStr);
      }else{
        this.printClick(id, batchCode, d);
      }
    },
    //打印
    printClick(id, batchCode, d) {
      // if (this.multipleSelection.length == 0) {
      //   this.$message.info("请勾选需要打印的批号");
      //   return;
      // }
      let batchCodes = [];
      var batchCodesArr=[];
      var selItemcode = d.row.reports.filter((pp) => pp.batchCode == batchCode);
      if (selItemcode) {
        d.row.reports.forEach((item) => {
          if (parseInt(item.seq / 4) == parseInt(selItemcode[0].seq / 4)) {
            batchCodes.push(item.batchCode);
          }
        });
        batchCodesArr.push(batchCodes);
      }
      this.$refs.qualityCertificate.print(id, batchCodesArr, this.trueName);
    },
    //成都物料打印
    printClick2(id,batchCodeStr) {
      let isWeightT = this.trueName?"1":"0";
      var printUrl = 'http://36.133.200.236:9094/jmreport/view/1013987156751409152?id='+id+'&batchCodeStr='+batchCodeStr+'&isWeightT='+isWeightT;
      var windowName = '打印页面';
      window.open(printUrl, windowName);
    },
    //批量打印
    printClicks(id) {
      var batchCodes = [];
      var ArrybatchCodes = [];
      if (this.multipleSelection.length == 0) {
        this.$message.info("请勾选需要打印的批号");
        return;
      } else {
        
        var result = [];
        for (var i = 0; i < this.multipleSelection.length; i += 4) {
          result.push(this.multipleSelection.slice(i, i + 4));
        }
        result.forEach((item) => {
          batchCodes=[];
          for (var i = 0; i < item.length; i++) {
            batchCodes.push(item[i].batchCode);
          }
          ArrybatchCodes.push(batchCodes);
        });
        
        
        // for (var j = 0; j < batchCodes.length; j ++) {
        //   _this.ArrybatchCodes.push(batchCodes.slice(j, j + 4));
        // }

        this.$refs.qualityCertificate.print(id, ArrybatchCodes, this.trueName);
      }

      this.$nextTick(() => {
        this.$refs.multipleTable.clearSelection();
      });
    },
    //多选打印
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //展示抽屉的方式
    showDrawerFunc(row) {
      this.showDrawer = true; //展开抽屉
      if (row) {
        this.processForm["id"] = row["id"];
        this.processForm["certificateNO"] = row["certificateNO"];
        this.processForm["serialNO"] = row["serialNO"];
        this.processForm["consignee"] = row["consignee"];
        this.processForm["destination"] = row["destination"];
        this.processForm["deliveryCondition"] = row["deliveryCondition"];
      }
    },
    //提交按钮被点击时
    submitBtnClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.postRequest("/qc/updateById", this.processForm).then((res) => {
            if (res.success) {
              this.loading = false;
              this.showDrawer = false;
              this.$message.success("编辑成功");
              this.$refs.processForm.resetFields();
              this.currPage = 1;
              this.currentPageChanged(this.currPage, this.pageSize);
            } else {
              this.loading = false;
              return false;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.elp {
  background: white;
  padding: 10px 20px;
}

/deep/ .inner-table {
  margin: 0;
  padding: 0;

  th,
  tr,
  td {
    margin: 0;
    padding: 0;
    background: #e1e1e1;
  }
}

.main-table {
  height: calc(100vh - 15rem);
  overflow-y: scroll;
}

.main-table::before {
  display: none;
}

.main-table-nopage {
  height: calc(100vh - 9.5rem);
}

.main-table-nopage-nosubmain {
  height: calc(100vh - 5.5rem);
}

.no-empty {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-header {
  background: #deebf6;
  color: #000000;
}

/deep/ .el-table__expanded-cell {
  padding: 0;
}
</style>

